import React, { useCallback } from "react";
import { ITableLayout } from "../../types";
import ColumnSetting from "../common/columnSetting";
import "./index.scss";

const TableLayout = (props: ITableLayout) => {
  const {
    children,
    title,
    header,
    columns = [],
    onColumnChange,
    columnSettiongIcon = ({ children }) => <>{children}</>,
    showColumnControl = true,
    actionBar,
  } = props || {};

  const ColumnControl = useCallback(
    () => (
      <ColumnSetting
        columns={columns}
        onChange={onColumnChange}
        columnSettiongIcon={columnSettiongIcon}
      />
    ),
    []
  );

  const ActionBar = useCallback(() => {
    if (!actionBar && showColumnControl) return <ColumnControl></ColumnControl>;

    if (typeof actionBar === "function") {
      const ActionBar = actionBar;

      return <ActionBar columnSetting={ColumnControl} />;
    }

    return <>{actionBar}</>;
  }, [actionBar]);

  const RenderTitle = ({ title }) => {
    if (!title) return <></>;

    if (typeof title === "string") return <h3>{title}</h3>;

    if (Object.prototype.toString.call(title) === "[object Function]") {
      const Title = title;

      return (
        <>
          <Title></Title>
        </>
      );
    }
  };

  return (
    <div className="table-layout">
      {header ? (
        header(props, ColumnControl)
      ) : (
        <div className="table-head">
          <div className="table-head-title">
            <RenderTitle title={title}></RenderTitle>
          </div>
          <div className="table-action-bar">
            <ActionBar />
          </div>
        </div>
      )}

      {children}
    </div>
  );
};

export default TableLayout;
