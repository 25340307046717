import React, { useState,useEffect } from "react";
import { Checkbox, Dropdown, Divider, Button } from "antd";
import "./index.scss";

const Setting = (props) => {
  let { columns, columnSettiongIcon: SettingIcon } = props;

  useEffect(() => {
    if (!SettingIcon) {
      SettingIcon = ({ children }) => <>{children}</>;
    } 
    else {
      // SettingIcon = ({ children }) => <SettingIcon>{children}</SettingIcon>;
    }
  }, []);

  //清除空值干扰项
  columns = columns.filter((item) => !!item);

  //转换为checkbox需要的数据类型
  let _columnsList = columns.map((item) => ({
    label: item.title,
    value: item.dataIndex ?? 0,
  }));

  const checkedAllList = (_columnsList || []).map((item) => {
    return item.value;
  });

  const [checkedList, setCheckedList] = useState(
    props.checkedList || checkedAllList || []
  );
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(true);

  const onCheckAll = (e) => {
    const _checkedList = e.target.checked ? checkedAllList : [];
    setCheckedList(_checkedList);
    setIndeterminate(false);
    setCheckAll(e.target.checked);

    props?.onChange?.(_checkedList);
  };
  const onChange = (checkedColumns) => {
    const _checkedList = [...checkedColumns];

    setCheckedList(_checkedList);
    setIndeterminate(
      !!checkedColumns.length && checkedColumns.length < _columnsList.length
    );
    setCheckAll(checkedColumns.length === _columnsList.length);

    props?.onChange?.(_checkedList);
  };

  const CheckList = () => {
    return (
      <div className="table-setting-list">
        <Checkbox
          indeterminate={indeterminate}
          checked={checkAll}
          onChange={onCheckAll}
        >
          全选/反选
        </Checkbox>
        <Divider style={{ margin: "10px 0" }} />
        <Checkbox.Group
          className="table-column-check-list"
          options={_columnsList}
          defaultValue={checkedList}
          onChange={onChange}
        />
      </div>
    );
  };

  return (
    <div className="table-setting">
      <Dropdown
        overlay={<CheckList></CheckList>}
        trigger={["click"]}
        placement="bottomRight"
      >
        <a>
          <SettingIcon>
            <Button type="link">显示列</Button>
          </SettingIcon>
        </a>
      </Dropdown>
    </div>
  );
};

export default Setting;
