import { combineReducers } from "redux";

export const initReducer = (reducer: any) => {
  if (!!reducer) return reducer;

  return (state: any) => {
    return state;
  };
};

const baseReducer = (state: any) => {
  return {
    login: false,
  };
};

const createReducer = (reducers: any) => {
  return combineReducers({
    init: baseReducer,
    ...reducers,
  });
};

//@ts-ignore
class ReducerRegistry {
  _reducers: any;
  _emitChange: (() => any) | null;
  constructor(defaultReducer: any) {
    // super();
    this._emitChange = null;
    this._reducers = defaultReducer || {};
  }

  getReducers() {
    return { ...this._reducers };
  }
  register(reducers: any) {
    //注册reducer到store中
    //reducers key&value
    if (!!reducers && !!this._emitChange) {
      this._reducers = { ...this._reducers, ...reducers };
      !!this._emitChange && this._emitChange();
    }
  }

  setChangeListener(store: any) {
    this._emitChange = () => {
      if (!store) return;

      store.replaceReducer(createReducer(this.getReducers()));
    };
  }
}

export default ReducerRegistry;
