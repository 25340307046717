import Register, { initReducer } from "./register";

const ApplyReducer = function (defaultReducer: any) {
  return {
    registerInstance: new Register(defaultReducer),
    init: initReducer(defaultReducer),
  };
};

export default ApplyReducer;
