//@ts-nocheck
import * as SagaEffects from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";

const { fork, cancel, take } = SagaEffects;

class ISagaRegistry {
  _sagas: Object;
  _emitChange: Function | null;
  registerSagas: any;
}

interface ISagaOption {
  effects: any;
  namespace?: string;
  extraType?: object;
}

const isArray = (data): boolean => {
  return Object.prototype.toString.call(data) === "[object array]";
};

const getEffectType = (key: string, extraType: object = {}) => {
  return extraType[key] || "takeEvery";
};

class SagaRegistry extends ISagaRegistry {
  constructor() {
    super();

    this._emitChange = null;
    this._sagas = {};
    this.registerSagas = {
      appliedEffects: [],
      cancelledEffects: [],
    };
  }

  getSagas() {
    return { ...this._sagas };
  }
  isSagaRegister(name: string) {
    return !!this._sagas[name];
  }
  sagaName({ key, namespace }): string {
    return `${namespace ?? "saga"}/${key}`;
  }
  cancelSagaName({ key, namespace }): string {
    return `${namespace ?? "saga"}/${key}/@@CANCEL_EFFECTS`;
  }
  appendSaga(options = {} as ISagaOption) {
    // debugger;
    if (!options.effects) {
      console.error(
        `${!!options.namespace ? "effect " + options.namespace : ""}`,
        "Saga undefined"
      );
      return;
    }

    const _that = this;

    const getTask = function (
      key: string,
      effect: any,
      namespace: string,
      extraType: object = {}
    ) {
      return function* () {
        const _sagaName: string = _that.sagaName({ key, namespace });
        _that.registerSagas.appliedEffects = [
          ..._that.registerSagas.appliedEffects,
          ...[_sagaName],
        ];

        let _effect = null;
        //定制effect类型
        let _effectType = getEffectType(key, extraType);
        if (!_effectType) {
          console.warn(
            `type ${extraType[key]} is incorrect, use default takeEvery instead`
          );
          _effectType = "takeEvery";
        }

        if (typeof effect === "object") {
          _effect = effect.effect;
          if (effect.option) {
            _effectType = effect.option.effectType || "tabkeEvery";
          }
        } else {
          _effect = effect;
        }

        const _effectCreator = SagaEffects[_effectType];

        yield _effectCreator(_sagaName, function* (args) {
          try {
            const _task = yield fork(_effect, { ...args, ...SagaEffects });

            const _cancelName: string = _that.cancelSagaName({
              key,
              namespace,
            });

            yield take(_cancelName);

            _that.registerSagas.cancelledEffects = [
              _that.registerSagas.cancelledEffects,
              ...[_cancelName],
            ];

            yield cancel(_task);
          } catch (ex) {
            console.error(_sagaName, ex, _effectType);
          } finally {
            // console.warn(yield cancelled())
          }
        });
      };
    };

    const _saga = function* () {
      const { effects, namespace, extraType = {} } = options;
      for (const key in effects) {
        if (_that.isSagaRegister(_that.sagaName({ key, namespace }))) {
          console.warn(
            `the saga key:${key} from:${namespace}`,
            "is register,please change another saganame"
          );
        } else {
          const _task = getTask(key, effects[key], namespace, extraType);
          yield fork(_task);
        }
      }
    };

    this._sagas = { ...this._sagas, [options.namespace || "saga"]: _saga };

    if (!!this._emitChange) {
      this._emitChange(_saga);
    }
  }
  register(options) {
    if (isArray(options)) {
      options.forEach((item, index) => {
        this.appendSaga(item);
      });
    } else {
      this.appendSaga(options);
    }
  }

  setChangeListener(listener) {
    this._emitChange = listener;
  }
}

export default SagaRegistry;
