//@ts-nocheck
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

let domainUrl: string = "";

export const requestDomainUrl = "";

const Service = axios.create({
  timeout: 100000,
  withCredentials: true,
  transformRequest: [
    function (data, headers: AxiosRequestHeaders | undefined) {
      if (!!data && !!headers) {
        if (headers["Content-Type"] === "application/x-www-form-urlencoded") {
          let _data = new FormData();
          Object.keys(data).forEach((key, value) => {
            _data.append(key, data[key]);
          });
          return _data;
        }
        return JSON.stringify(data);
      }
      return data;
    },
  ],
  transformResponse: [
    function (data) {
      try {
        return JSON.parse(data);
      } catch (ex) {
        return data;
      }
    },
  ],
});

const PostMethod = (param: AxiosRequestConfig) => {
  let _obj: AxiosRequestConfig = {
    baseURL: requestDomainUrl,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    ...(param ?? {
      data: {},
    }),
  };

  return Service(_obj);
};

const GetRequest = (param?: AxiosRequestConfig) => {
  let _obj: AxiosRequestConfig = {
    baseURL: requestDomainUrl,
    method: "get",
    params: param?.data || {},
    ...(param ?? {}),
  };

  return Service(_obj);
};

const PutMethod = (param?: AxiosRequestConfig) => {
  const { data = {}, ...restParam } = param;
  let _obj: AxiosRequestConfig = {
    baseURL: requestDomainUrl,
    method: "put",
    params: data,
    ...(restParam ?? {}),
  };

  return Service(_obj);
};

const DelMethod = (param?: AxiosRequestConfig) => {
  const { data = {}, ...restParam } = param;
  let _obj: AxiosRequestConfig = {
    baseURL: requestDomainUrl,
    method: "delete",
    params: data,
    ...(restParam ?? {}),
  };

  return Service(_obj);
};

export { Service };

export default {
  Get: GetRequest,
  Post: PostMethod,
  Delete: DelMethod,
  Put: PutMethod,
};
