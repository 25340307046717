import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getPaginationInfo } from "./pagination";
import "./index.scss";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const CustomTable = (props: any) => {
  let {
    loadData,
    pagination,
    rowSelection,
    expandable,
    selectable = false,
    selectType = "checkbox",
    defaultPagination,
    setSelectedRows,
    rowKey,
    ...restProps
  } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [pagi, setPagi] = useState(
    getPaginationInfo(pagination || defaultPagination)
  );

  useEffect(() => {
    setPagi(getPaginationInfo(pagination || defaultPagination));
  }, [pagination]);

  const _requestData = (params = defaultPagination) => {
    setLoading(true);

    loadData?.(params, ({ data, pagination }) => {
      setLoading(false);
      setPagi({ ...pagi, ...(pagination || {}) });
    });
  };

  const handleChange = (params, filters, sorter, extra) => {
    _requestData(params);
  };

  if (selectable) {
    restProps = {
      ...restProps,
      rowSelection: {
        type: selectType,
        onChange: function (
          selectedRowKeys: React.Key[],
          selectedRows: DataType[]
        ) {
          setSelectedRows?.(selectedRows);
        },
        getCheckboxProps: (record) => {
          return {
            disabled: false,
            name: record.name,
          };
        },
        ...rowSelection,
      },
    };
  }

  if (expandable) {
    restProps = {
      ...restProps,
      expandable: {
        ...expandable,
      },
    };
  }

  const getRowKey = (rowKey, record, index) => {
    // console.log(record,rowKey, "rowkey");
    if (!rowKey) return Symbol(`table-row-${index}`).toString();

    if (typeof rowKey === "function") return rowKey(record);

    if (typeof rowKey === "string") return `table-row-${record[rowKey]}`;

    console.warn("rowKey 只能为 'string' 或者 'function'");
  };

  if (restProps?.dataSource?.find?.((item) => !item.children)) {
    restProps = {
      ...restProps,
      rowKey: (record) => {
        const _time=new Date().getTime();
        const _rowKey = getRowKey(rowKey, record, _time);
        return _rowKey;
      },
    };
  }

  return (
    <Table
      loading={loading}
      size={"small"}
      showSorterTooltip={false}
      ellipsis={{
        showTitle: false,
      }}
      pagination={props.pagination === false ? false : pagi}
      onChange={handleChange}
      {...restProps}
    ></Table>
  );
};

export default CustomTable;
